<template>
  <div id="settings-wrapper">
    <v-card
      id="settings"
      class="py-2 px-4"
      color="rgba(0, 0, 0, .3)"
      dark
      link
      min-width="100"
      style="position: fixed; bottom: 80px; right: -35px; border-radius: 8px; z-index: 1;"
    >
      <v-icon large>
        mdi-cog
      </v-icon>
    </v-card>

    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      activator="#settings"
      bottom
      content-class="v-settings"
      left
      nudge-left="8"
      offset-x
      origin="bottom right"
      transition="scale-transition"
    >
      <v-card class="text-center mb-0" width="300">
        <v-card-text>
          <strong class="mb-3 d-inline-block">網站識別主色</strong>

          <v-item-group v-model="color" mandatory>
            <v-item v-for="color in colors" :key="color" :value="color">
              <template v-slot="{ active, toggle }">
                <v-avatar
                  :class="active && 'v-settings__item--active'"
                  :color="color"
                  class="v-settings__item mx-1"
                  size="25"
                  @click="toggle"
                />
              </template>
            </v-item>
          </v-item-group>

          <v-divider
            :style="{ background: $vuetify.theme.themes[theme].divider }"
            class="my-4"
          />

          <strong class="mb-3 d-inline-block">側選單背景色</strong>

          <v-item-group v-model="gradient" mandatory>
            <v-item
              v-for="(scrim, index) in gradients"
              :key="scrim"
              :value="index"
              class="mx-1 side-menu-bg"
            >
              <template v-slot="{ active, toggle }">
                <v-avatar
                  :class="active && 'v-settings__item--active'"
                  :color="scrim"
                  class="v-settings__item"
                  size="24"
                  @click="toggle"
                />
              </template>
            </v-item>
          </v-item-group>

          <v-divider
            :style="{ background: $vuetify.theme.themes[theme].divider }"
            class="my-4"
          />

          <v-row align="center" no-gutters>
            <v-col cols="auto">
              暗色模式
            </v-col>

            <v-spacer />

            <v-col cols="auto">
              <v-switch
                v-model="$vuetify.theme.dark"
                class="ma-0 pa-0"
                color="secondary"
                hide-details
              />
            </v-col>
          </v-row>

          <v-divider
            :style="{ background: $vuetify.theme.themes[theme].divider }"
            class="my-4"
          />

          <v-row align="center" no-gutters>
            <v-col cols="auto">
              縮小側選單
            </v-col>

            <v-spacer />

            <v-col cols="auto">
              <v-switch
                v-model="mini"
                class="ma-0 pa-0"
                color="secondary"
                hide-details
              />
            </v-col>
          </v-row>

          <v-divider
            :style="{ background: $vuetify.theme.themes[theme].divider }"
            class="my-4"
          />

          <v-row align="center" no-gutters>
            <v-col cols="auto">
              是否顯示側選單背景圖片
            </v-col>

            <v-spacer />

            <v-col cols="auto">
              <v-switch
                v-model="drawerImage"
                class="ma-0 pa-0"
                color="secondary"
                hide-details
              />
            </v-col>
          </v-row>

          <v-divider
            :style="{ background: $vuetify.theme.themes[theme].divider }"
            class="my-4"
          />

          <strong class="mb-3 d-inline-block">選擇側選單背景圖片</strong>

          <v-card :disabled="!drawerImage">
            <v-item-group
              v-model="image"
              class="d-flex justify-space-between mb-3"
            >
              <v-item
                v-for="(img, index) in images"
                :key="img"
                :value="index"
                class="mx-1"
              >
                <template v-slot="{ active, toggle }">
                  <v-sheet
                    :class="active && 'v-settings__item--active'"
                    class="d-inline-block v-settings__item"
                    @click="toggle"
                  >
                    <v-img :src="img" height="100" width="50" />
                  </v-sheet>
                </template>
              </v-item>
            </v-item-group>
          </v-card>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
// Mixins
import Proxyable from 'vuetify/lib/mixins/proxyable'
import colors from 'vuetify/lib/util/colors'

// Vuex
import { get, sync } from 'vuex-pathify'

export default {
  name: 'DashboardCoreSettings',

  mixins: [Proxyable],

  data: () => ({
    color: colors.red.darken1,
    colors: [
      colors.red.darken1,
      colors.orange.darken2,
      colors.yellow.darken3,
      colors.green.darken2,
      colors.blue.darken1,
      '#787878'
    ],
    menu: false,
    saveImage: ''
  }),

  computed: {
    ...sync('app', ['drawer', 'drawerImage', 'mini']),
    ...sync('user', ['drawer@gradient', 'drawer@image']),
    ...get('user', ['images', 'gradients']),
    theme () {
      return this.$vuetify.theme.dark ? 'dark' : 'light'
    }
  },

  watch: {
    color (val) {
      this.$vuetify.theme.themes[this.isDark ? 'dark' : 'light'].primary = val
    }
  }

  // mounted () {
  //   document.getElementsByClassName('side-menu-bg').style.borderWidth = '1px 5px 10px 20px'
  //   document.getElementsByClassName('side-menu-bg').style.borderColor = 'red'
  // },
}
</script>

<style lang="sass">
.v-settings
  .v-item-group > *
    cursor: pointer

  &__item
    border-width: 3px
    border-style: solid
    border-color: #dddddd !important

    &--active
      border-color: #00cae3 !important
</style>
